import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import "./VideoTask.css";
import PageWrapper from "UI/PageWrapper";
import VideoTaskDetails from "./components/VideoTaskDetails";
import VideoTaskQuestion from "./components/VideoTaskQuestion";
import VideoTaskReward from "./components/VideoTaskReward";
import { useLocation, useNavigate } from "react-router-dom";
import Task from "types/task";
import doneVideoTask from "api/done-video-task";
import VideoTaskStatus from "./components/VideoTaskStatus";
import RewardScreen from "UI/RewardScreen";
import AppContext from "utils/app-context";
import tg from "utils/tg";
import Tag from "types/tag";


type Props = {
  task:Task
  tags:Tag[] | null
  settags:Dispatch<SetStateAction<Tag[] | null>>
  next:() => void
};

const VideoTask = ({task,settags,tags,next}: Props) => {
  const [opened, setopened] = useState(false);
  const [loading, setloading] = useState(false);
  const [answer, setanswer] = useState<null | boolean>(null);
  const [result, setresult] = useState(false)
  const [finalReward, setfinalReward] = useState<null | number>(null)
  const {user} = useContext(AppContext)
  const navigate = useNavigate()
  const [bid, setBid] = useState<number>((task?.bid_min || 0));
  useEffect(() => {
    if(answer === null || !task) return
    doneVideoTask(task.id,answer,bid).then(res => {
      if (res) {
        setresult(true)
      }
    })
    
  }, [answer])
  useEffect(() => {
   
  }, []);

  useEffect(() => {
    if(task.status === 0){
      document.querySelector('button')?.focus()
    const onBlur = () => {
      const iframe = document.querySelector('iframe')
        if (
            document.activeElement &&
            document.activeElement.nodeName.toLowerCase() === 'iframe' &&
            iframe &&
            iframe === document.activeElement
        ) {
          if(!task) return
          const seconds = task.status === 0 ? Number(task?.duration) * 1000 : 0
          setTimeout(() => setopened(true), seconds);
          window.removeEventListener('blur', onBlur);
          }
    };

    window.addEventListener('blur', onBlur);

    return () => {
        window.removeEventListener('blur', onBlur);
    };
    } else setopened(true)
}, []);

  return (
    <>
      {
          task?.status === 0
          ? <VideoTaskDetails rep={Number(task?.reward) || 0} min_bid={(task?.bid_min || 0)} bid={bid} setBid={setBid} task={task} settags={settags} tags={tags}/>
          : task && <VideoTaskStatus task={task} setfinalReward={setfinalReward}/>
      }
      <div
        className="iframe-container"
        dangerouslySetInnerHTML={{ __html: (task?.iframe_video) || '' }}
      />
      {/* <div
        className="iframe-container"
        dangerouslySetInnerHTML={{ __html: `
          <video preload="auto" autoplay #videoPlayer>
            <source src="www.youtube.com/watch?v=3bGNuRtlqAQ" type="video/mp4" />
          </video>
          `}}
      /> */}
      {task && <VideoTaskQuestion task={task} disabled={task?.status !== 0} opened={opened} handleQuestion={setanswer} loading={loading} setloading={setloading} answer={answer}/>}
      {(result && answer !== null) && (
        <VideoTaskReward
          answer={answer}
          TaskReward={Number(task?.reward)}
          close={() => setTimeout(next, 500)}
        />
      )}
      {finalReward && (
        <div style={{position:'absolute',inset:0}} className="final-reward-screen">
          <RewardScreen rewardCount={finalReward} rewardData={{reward_type:'rep',status:1,balance:user?.balance}} close={() => navigate('/main/tasks')}/>
        </div>
      )}
    </>
  );
};

export default VideoTask;
