import { AnimatePresence, motion, Variants } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation,Location, useNavigate } from 'react-router-dom';
import Task from 'types/task';
import AppContext from 'utils/app-context';
import VideoTask from './VideoTask';
import Tag from 'types/tag';
import getTags from 'api/get-tags';
import PageWrapper from 'UI/PageWrapper';

type Props = {}
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };
  

const VideoTaskSlider = (props: Props) => {
    const { state: task } = useLocation() as Location<Task | undefined>;
    const {tasks,updateTasks} = useContext(AppContext)
    const [videoTasks, setvideoTasks] = useState(getAndSort(tasks))
    const index = videoTasks?.findIndex(({id}) => id === task?.id)
    const [currentTask, setcurrentTask] = useState<Task | undefined>(task)
    const [[page, direction], setPage] = useState([(index || 0), 0]);
    const navigate = useNavigate()
    const [tags, settags] = useState<Tag[] | null>(null)
    
    const paginate = (newDirection: number,closeIfVoted?:true) => {
        let newIndex = page + newDirection
        if(!videoTasks || !videoTasks.length) return
        if(newIndex < 0) newIndex = videoTasks.length-1
        else if(newIndex > videoTasks.length-1) newIndex = 0  
        console.log("🚀 ~ paginate ~ videoTasks:", videoTasks.length,newIndex)

        setPage([newIndex, newDirection]);
        setcurrentTask(videoTasks[newIndex])
      };
      useEffect(() => {
        getTags().then(res => {
          if(res) settags(
            res.filter(({id}) => videoTasks?.find((t) => t.tag_id === id))
            .map(t => ({...t,active:true}))
          )
        })
      }, [])
      useEffect(() => {
        setvideoTasks(tasks?.filter(({is_video,tag_id}) => {
          const isActive = tags?.find(({id,active}) => id === tag_id && active )
          return is_video && isActive
        }).sort((taskA) => typeof taskA.answer === 'number' ? -1 : 1))
      }, [tags])
      useEffect(() => {
        if(!tasks || videoTasks) return
        setvideoTasks(getAndSort(tasks))
      }, [task])


      const next = async () => {
        const res = await updateTasks()
        if(!res?.find(({answer}) => answer === null)) {
          navigate('/main/tasks')
          return
        }
        setvideoTasks(getAndSort(res))
        paginate(1);
      }

  return (
    <PageWrapper className='video-tasks-container'>
        <AnimatePresence initial={false} custom={direction}>
            {videoTasks && <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            className='video-task-page'
            animate="center"
            // dragListener={false}
            exit="exit"
            transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            onDragStart={(e,) => {
                const targetElement = e.target as HTMLElement;
            
                // Указываем класс, наличие которого должно прервать действие
                const forbiddenClass = 'video-task-question-body';
                console.log(targetElement.classList);
                
                // Проверяем, если у элемента или его родителя есть класс, прерываем действие
                if (targetElement.classList.contains(forbiddenClass) || targetElement.closest(`.${forbiddenClass}`)) {
                    e.preventDefault(); // Прерывает действие drag
                    console.log("🚀 ~ VideoTaskSlider ~ preventDefault:")
                    return;
                }
            
            }}
            dragElastic={1}
            onDrag={(e,{offset}) => {
              console.log("🚀 ~ VideoTaskSlider ~ offset:", offset)
              
            }}
            onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
                }
            }}
            >
                {currentTask && <VideoTask task={currentTask} settags={settags} tags={tags} next={next}/>}
        </motion.div>}
      </AnimatePresence>
    </PageWrapper>
  )
}

const getAndSort = (tasks?:Task[] | null) => tasks
  ?.filter(({is_video}) => is_video)
  .sort((taskA) => typeof taskA.answer === 'number' ? -1 : 1)

const variants:Variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? '100%' : '-100%',
        scale:0.7
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      scale:1
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        position:'fixed',
        scale:0.7,
        x: direction < 0 ? '100%' : '-100%',
      };
    }
  };

export default VideoTaskSlider