import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id || process.env.REACT_APP_TEST_ID;

interface TaskDoneResponce {
  status: null;
  reward: number;
  reward_type: string;
  balance: number;
  error?:string
}
export default async (task_id:string | number):Promise<TaskDoneResponce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "videotask_claim";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("task_id", String(task_id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
