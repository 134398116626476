import React, { useEffect, useState } from 'react'
import Icon from 'UI/Icon'
import formatTime from 'utils/format-time'

type Props = {
    seconds:number
    notCount?:true
}

const TaskTimer = ({seconds}: Props) => {
  const [secs, setsecs] = useState({seconds,dots:true})
  useEffect(() => {
    const interval = setInterval(() => {
        setsecs(prev => ({seconds:prev.seconds-1,dots:!prev.dots}))
    }, 1000);
    return () => clearInterval(interval)
  }, [])
  if (secs.seconds <= 0) return null

    return (
    <div className='tasks-timer'>
        <Icon icon='timer'/>
        {formatTime(secs.seconds,secs.dots)}
    </div>
  )
}

export default TaskTimer