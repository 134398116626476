import { useContext, useEffect, useRef, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import './tasks.css'
import getTasks from 'api/get-tasks'
import TaskModal from './TaskModal/TaskModal'
import Task from 'types/task'
import { AnimatePresence, motion } from 'framer-motion'
import Icon from 'UI/Icon'
import AppContext from 'utils/app-context'
import TaskCard from './TaskCard'

const Tasks = () => {
    const [openedTask, setopenedTask] = useState<Task | null>(null)
    const [showComplited, setshowComplited] = useState(false)
    const timer = useRef<NodeJS.Timeout | null>(null)
    const {t,settasks,tasks,updateTasks} = useContext(AppContext)
    const text = t('tasksPage')
    

    useEffect(() => {
        updateTasks()
    }, [])
    useEffect(() => {
        if(!tasks) return
        if(timer.current) clearTimeout(timer.current)

    timer.current = setTimeout(() => {
        settasks(prev => {
            if(!prev) return prev
            return prev.map(t => ({...t,seconds:String(Number(t.seconds) - 1)}))
        })
    }, 1000);

    },[tasks])
    
  return (
    <>
    <PageWrapper>
        <div className="tasks-screen">
        <h2 className="tasks-title-h2">{text.title}</h2>
        <div className="tasks-types">
            <div className={`active ${showComplited && 'type-desactive'}`} onClick={() => setshowComplited(false)}>{text.active}</div>
            <div className={`completed ${!showComplited && 'type-desactive'}`} onClick={() => setshowComplited(true)}>{text.completed}</div>
        </div>
            <div className='tasks-items tasks-items-active'>
                <AnimatePresence mode='wait'>
            <motion.div
                key={Number(showComplited)}
                initial={{opacity:0,translateX:50}}
                animate={{opacity:1,translateX:0}}
                transition={{duration:0.1}}
                exit={{opacity:0,translateX:-50}}
                style={{display:'flex',flexDirection:'column',rowGap:14}}
            >
              {/* {(showComplited && tasks?.filter(({ status, is_video }) => Number(is_video) === 1 ? status !== 1 : status === 1).length === 0) && <p>{text.allCompleted}</p>} */}

                {tasks ? tasks.map((task) => {
                    const { status, is_video,title } = task;
                    
                    if (showComplited) {
                        // Показываем только завершённые задачи или задачи с видео, где статус не 0
                        if (Number(is_video) === 1 && status === 0) return null;
                        if (Number(is_video) !== 1 && status !== 1) return null;
                    } else {
                        // Показываем только незавершённые задачи со статусом 0 или 2, и задачи с видео только со статусом 0
                        if (Number(is_video) === 1 && status !== 0) return null;
                        if (status !== 0 && status !== 2) return null;
                    }

                    return <TaskCard key={task.id} setopenedTask={setopenedTask} task={task} />;
                }) : <Icon icon="loading" />}

            </motion.div>
        </AnimatePresence>
            </div>
        </div>
    </PageWrapper>
    <TaskModal task={openedTask} openedTask={setopenedTask} refreshTasks={updateTasks}/>
    </>
  )
}



export default Tasks