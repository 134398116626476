export default (price: string | number, withDecimal: boolean = false) => {
    // Преобразуем цену в строку и разбиваем на целую и дробную части
    const [integerPart, fractionalPart = ''] = String(Math.abs(Number(price))).split('.'); // используем Math.abs, чтобы убрать знак

    // Форматируем целую часть с пробелами
    const s = integerPart.length;
    const chars = integerPart.split('');
    const strWithSpaces = chars.reduceRight((acc, char, i) => {
        const spaceOrNothing = ((((s - i) % 3) === 0) ? ' ' : '');
        return (spaceOrNothing + char + acc);
    }, '');

    // Готовим отформатированную целую часть
    const formattedIntegerPart = strWithSpaces[0] === ' ' ? strWithSpaces.slice(1) : strWithSpaces;

    // Обрабатываем дробную часть, если `withDecimal` равно true
    const formattedFractionalPart = withDecimal
        ? `.${(fractionalPart + '00').slice(0, 2)}`
        : '';

    // Проверяем, было ли число отрицательным, и добавляем знак, если нужно
    const sign = Number(price) < 0 ? '-' : '';

    // Комбинируем целую и дробную части
    return `${sign}${formattedIntegerPart}${formattedFractionalPart}`;
};
