interface Point {
  x: number;
  y: number;
}

export default function generateRandomCurvePoints(
  start: Point,
  end: Point,
  numPoints: number = 10,
  amplitude: number = 100
): Point[] {
  const points: Point[] = [];
  const step = 1 / (numPoints - 1);

  // Определяем случайные точки управления
  const controlX =
    start.x + (end.x - start.x) / 2 + (Math.random() - 0.5) * amplitude;
  const controlY = start.y - amplitude + (Math.random() - 0.5) * amplitude;

  for (let i = 0; i < numPoints; i++) {
    const t = i * step;

    // Квадратичная кривая Безье с случайными точками управления
    const x =
      Math.pow(1 - t, 2) * start.x +
      2 * (1 - t) * t * controlX +
      Math.pow(t, 2) * end.x;

    const y =
      Math.pow(1 - t, 2) * start.y +
      2 * (1 - t) * t * controlY +
      Math.pow(t, 2) * end.y;

    points.push({ x, y });
  }

  return points;
}
