import { AnimatePresence, motion } from 'framer-motion'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import Alert from 'types/alert'
import { MainContext } from '../App'

type Props = {
    notification?:Alert | false

}

const Notification = ({notification}: Props) => {
  const {setNotification,updateAlert} = useContext(MainContext)
  return (
    <AnimatePresence>
       {notification && <motion.div 
            initial={{translateY:-100,opacity:0}}
            animate={{translateY:0,opacity:1}}
            exit={{translateY:-100,opacity:0}}
            transition={{ease:'easeInOut'}}
            key={Date.now()}
            className="notification"
        >
            <p className="notification-text" style={{color:notification.color}} dangerouslySetInnerHTML={{__html:notification.text}}/>
            <button onClick={() => updateAlert()} className="close-notification">
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.2501 18.7498L18.7501 31.2498M18.7501 18.7498L31.2501 31.2498M45.8334 24.9998C45.8334 36.5058 36.506 45.8332 25.0001 45.8332C13.4941 45.8332 4.16675 36.5058 4.16675 24.9998C4.16675 13.4939 13.4941 4.1665 25.0001 4.1665C36.506 4.1665 45.8334 13.4939 45.8334 24.9998Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
            </button>
        </motion.div>}
    </AnimatePresence>
  )
}

export default Notification