import React, { useEffect, useState } from 'react';

type Props = {
    initialSeconds?: number | null; // начальное значение секунд
};

const UpdateTimer = ({ initialSeconds = 0 }: Props) => {
    const [seconds, setSeconds] = useState<number | null>(initialSeconds);

    useEffect(() => {
     if (!seconds) return
        const interval = setInterval(() => {
            setSeconds(prevSeconds => Number(prevSeconds) - 1);
        }, 1000);

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(interval);
    }, []);

    if (seconds === null) return null;

    return (
        <div className='update-timer'>
            <img src="/img/clock.svg" alt="" />
            <p>{formatTime(seconds)}</p>
        </div>
    );
};

function formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    const formatNumber = (num: number) => String(num).padStart(2, '0');

    return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(secs)}`;
}

export default UpdateTimer;
