import React, { useState } from 'react'
import Icon from 'UI/Icon'
type ID = number | string

type Props = {
    selectedId?:ID,
    options?:{id:ID,name:string}[]
    onClick?: (id:ID) => void 
}

const Select = ({selectedId,options,onClick}: Props) => {
    const [opened, setopened] = useState(false)
  return (
    <div className="custom-select-wrapper" >
    <div className={`custom-select ${opened && 'open'}`} onClick={() => setopened(!opened)}>
        <div className="custom-select-trigger">{options?.find(({id}) => id == selectedId)?.name}</div>
        <div className="custom-select-arrow">
            <img src="/img/select-arrow-down.svg" alt="" className="arrow-down2"/>
            <img src="/img/select-arrow-top.svg" alt="" className="arrow-top2"/>
        </div>
        <div className="custom-options">
            {
                options ? options.map(({id,name}) => {
                    if(id == selectedId) return null
                    return (
                        <span
                            key={id}
                            className="custom-option" 
                            onClick={onClick && (() => onClick(id))}
                        >{name}</span>
                    )
                })
                : <Icon icon='loading'/>
            }
        </div>
    </div>
</div>
  )
}

export default Select