import React, { useContext, useState } from 'react'
import AppContext from 'utils/app-context'

type Props = {
  age?:number,
  reward?:number,
}

const EntryScreenSecond = ({age,reward}: Props) => {
  const {t} = useContext(AppContext)
  const text = t('onBoardingScreen')
  
    function getYearWord(years?: number): string {
      if (typeof years !== 'number') return ''
      const lastDigit = years % 10;
      const lastTwoDigits = years % 100;
      
      if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
        return text.years; // для чисел от 11 до 19
      }

      if (lastDigit === 1) {
        // для чисел, оканчивающихся на 1 (кроме 11-19)
        if (years < 10) return text.oneYear; 
        else return text.oneYearAtEnd; 
        
      }

      if (lastDigit >= 2 && lastDigit <= 4) {
        return text.years_v2; // для чисел, оканчивающихся на 2, 3 или 4 (кроме 11-19)
      }

      return text.years; // для остальных случаев
    }


  return (
    <div className="screen screen-2">
            <div className="check">
              <h2 className="check-title">{text.tgAge}</h2>
              <p className="check-years">
                <span className="big-num">{age}</span> {getYearWord(age)}
              </p>
              <p className="pre-reward">{text.Ureward}</p>
              <div className="rep-count big-num">+ {reward} REP</div>
            </div>
          </div>
  )
}

export default EntryScreenSecond