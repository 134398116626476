import React from 'react'
import { useNavigate } from 'react-router-dom'
import Task from 'types/task'
import Img from 'UI/Img'
import TaskTimer from 'UI/TaskTimer'
import formatNum from 'utils/format-num'

type Props = {
    task:Task
    setopenedTask:(t:Task) => void
}
const LIMIT = 40
const TaskCard = ({task,setopenedTask}: Props) => {
    const navigate = useNavigate()

    const {id,title,reward,icon,subtitle,reward_type,is_gold_task,is_video,tag_title,seconds,status,vote_reward,is_claimed} = task

    return (
        <div
            className={Number(is_gold_task) ? "tasks-item" : 'completed-item'}
            key={id}
            onClick={() => Number(is_video) ? navigate('/main/video-task',{state:task}) : setopenedTask(task)}
        >
            <Img src={Number(is_video) ? '/img/tasks/video.svg' : icon} size={33}/>
        <div className="tasks-content">
            <div className="tasks-title">
            {title}
            </div>
            <div className="tasks-subtitle">
                {subtitle.length > LIMIT ? `${subtitle.slice(0,LIMIT)}...` : subtitle}
            </div>
            <div className="tasks-rep">
                {reward_type === 'balance'
                ? <>
                    <img src="/img/coin4.png"/>
                    +{formatNum(reward)} 
                </>
                : <>
                <span className="big-green">REP</span>
                +{formatNum(reward)} points
                </>
            } 
            </div>
        </div>
        {!!Number(is_video) &&
            <div className='task-video-attrs'>
                <span className='task-video-tag'>{tag_title}</span>
                {status === 1 ? <p className='result-txt'>{vote_reward && !is_claimed ? 'Claim' : 'Result'}</p> :<TaskTimer notCount seconds={Number(seconds)}/>}
            </div>
        }
        <div className="tasks-arrow">
        <TaskIcon status={task.status}/>
        </div>
    </div>
    )
}
const TaskIcon = ({status}:{status:Task['status']}) => {
    let icon

    switch (status) {
        case 0:
            icon = (
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.25 23L18.75 15.5L11.25 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            )
            break;
    
            case 1:
                icon = (<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.25 15.5L13.75 18L18.75 13M27.5 15.5C27.5 22.4036 21.9036 28 15 28C8.09644 28 2.5 22.4036 2.5 15.5C2.5 8.59644 8.09644 3 15 3C21.9036 3 27.5 8.59644 27.5 15.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>)
                break
            case 2:
                icon = (
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 6.5V14H19.625M26.5 14C26.5 20.9036 20.9036 26.5 14 26.5C7.09644 26.5 1.5 20.9036 1.5 14C1.5 7.09644 7.09644 1.5 14 1.5C20.9036 1.5 26.5 7.09644 26.5 14Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                )
            break

    }

    return icon
} 
export default TaskCard