import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useNavigate, useRoutes, useLocation } from "react-router-dom";
import routes from "./routes/app-routes";
import tg from "utils/tg";
import Notification from "UI/Notification";
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import useAppData from "hooks/use-app-data";
import NavBar from "UI/NavBar";
import getAlert from "api/get-alert";
import Alert from "types/alert";
type MainContextProps = {
  setNotification:Dispatch<SetStateAction<Alert | false>>
  updateAlert:() => Promise<void>
}

export const MainContext = createContext<MainContextProps>({} as MainContextProps)

function App() {
  const { AppContext, AppContextValue } = useAppData();
  const [notification, setNotification] = useState<Alert | false>(false)
  const element = useRoutes(routes);
  const location = useLocation();
  
  const updateAlert = async () => {
    setNotification(false)
    const result = await getAlert()
    if (result) setNotification(result)
  }

  useEffect(() => {
    updateAlert()
    tg.disableVerticalSwipes()
  }, [])
  if (!element || !AppContextValue.user) return null;
  return (
    <TonConnectUIProvider manifestUrl={window.location.origin + '/tonconnect-manifest.json'}>
      <AppContext.Provider value={AppContextValue}>
        <MainContext.Provider value={{setNotification,updateAlert}}>
        <AnimatePresence mode="sync">
          {React.cloneElement(element, { key: location.pathname })}
        </AnimatePresence>
        <Notification notification={notification}/>
        <NavBar/>
        </MainContext.Provider>
      </AppContext.Provider>
    </TonConnectUIProvider>
  );
}

export default App;
