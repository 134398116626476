import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import "./onboarding-style.css";
import PageWrapper from 'UI/PageWrapper';
import AppContext from 'utils/app-context';
import getDailyReward from 'api/get-daily-reward';
import tg from 'utils/tg';

type Props = {}

const DailyReward = (props: Props) => {
  const navigation = useNavigate()
  const {user,t} = useContext(AppContext)
  const text = t('dailyRewardScreen')
  const errors = t('errors')
  if(!user) return null

  if (!user.everyday_task) {
    navigation('/main/home')
    return null
  }
  const {day,reward,text:everyday_task_text} = user.everyday_task

  const getReward = async () => {
    const res = await getDailyReward()
    if (!res?.success) tg.showPopup({message:errors.dailyReward})
    navigation('/main/home')
  }

  return (
    <PageWrapper className="screen reward-screen full-height" style={{height:'100vh !important'}}>
        <h2 className="reward-day">{text.day} {day}</h2>
        <p className="reward-text">{text.urDailyReward}</p>
        <div className="reward">
            <img src="img/coin-2.png" alt=""/>
            <div className="reward-count">+ {reward}</div>
        </div>
        <p className="reward-text-2" dangerouslySetInnerHTML={{__html:everyday_task_text}}></p>
        <div className="reward-button" onClick={getReward} >{text.getReward}</div>
    </PageWrapper>
  )
}

export default DailyReward