import React, { useContext } from "react";
import AppContext from "utils/app-context";
import formatNum from "utils/format-num";


type Props = {
  count?:number,
  reward?:number,
}

const EntryScreenThird = ({count,reward}: Props) => {
  const {t} = useContext(AppContext)
  const text = t('onBoardingScreen')

  return (
    <div className="screen screen-3">
      <div className="page-3">
        <p>{text.onboardingText1}</p>
        <p>{text.onboardingText2}</p>
        <h2>
        {text.oneOfFirst} <br /> {count && formatNum(count)} / 1 000 000
        </h2>
        <div className="reward">
          <img src="img/coin-2.png" alt="" />
          <div className="reward-count">+ {reward && formatNum(reward)}</div>
        </div>
      </div>
    </div>
  );
};

export default EntryScreenThird;
