import { Outlet, useNavigate } from "react-router-dom";
import "./styles/main.css";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import AppContext from "utils/app-context";
import tg from "utils/tg";


const Main = () => {
  const {redicrectVideoTask,user,updateTasks} = useContext(AppContext)
  const navigate = useNavigate()
  const [fetching, setfetching] = useState(false)

  
  useEffect(() => {
    if(user && typeof redicrectVideoTask.current === 'number' && !fetching) {
      console.log("🚀 ~ useEffect ~ !user || !redicrectVideoTask.current:", user,redicrectVideoTask.current)
      setfetching(true)
      updateTasks().then(res => {
        if(!res) return
        const task = res?.find(({id}) => id === String(redicrectVideoTask.current))
        console.log("🚀 ~ updateTasks ~ task:", task)
        
        if(!task) {
          tg.showAlert('We can\'t find the video-task that was shared with you, maybe it is not available to you')
        } else navigate('/main/video-task',{state:task})
    
        redicrectVideoTask.current = true
      }).finally(() => {
        setfetching(false)
      })
    }
    
  }, [user])
  return (
    <Outlet />
  );
};

export default Main;
