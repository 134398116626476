import React, { useContext } from 'react'
import PageWrapper from 'UI/PageWrapper'
import './friends.css'
import AppContext from 'utils/app-context'
import formatNum from 'utils/format-num'
import tg from 'utils/tg'
const tg_id = tg.initDataUnsafe.user?.id;
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) || process.env.REACT_APP_SHARE_LINK || ''

const Friends = () => {
    const {user,t} = useContext(AppContext)
    const text = t('frensPage')
    const copyShareLink = (e:React.MouseEvent) => {
        e.currentTarget.textContent = text.linkCopied
        navigator.clipboard.writeText(inviteLink)
    }
    const TelegramShare = () => {
        tg.openTelegramLink(`https://t.me/share/url?url=${inviteLink}&text=${encodeURIComponent(text.shareText)}`)
    }

  return (
    <PageWrapper>
        <div className="friends-screen">
    <h2 className="friends-title">
        {text.pageTitle}
    </h2>
    <p className="friends-text" dangerouslySetInnerHTML={{__html:(user?.friends_text || '')}}></p>
    <div className="total-referral">
        {text.totalIncome}
        <div className="total-money">
            <img src="/img/coin4.png" alt="" width="30px" height="30px"/>
            + {user?.friends_list && formatNum(user?.friends_list.reduce((sum,{coins}) => sum + coins,0) || 0)}
        </div>
    </div>
    <div className="yourf">{text.yourFrens} ({user?.friends}):</div>
    <div className="friends-items">
        {user?.friends_list.map(({coins,name,photo},i) => (
             <div className="friend-item" key={i}>
             <div className="fried-info">
                 <img src={photo} alt=""/>
                 {name}
             </div>
             <div className="plus-money">
                 <img src="/img/coin4.png" alt="" width="30px" height="30px"/>
                 + {formatNum(coins)}
             </div>
         </div>
        ))}
        
    </div>
    <div className="link" onClick={copyShareLink}>
        {inviteLink}
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 16C2.9 16 2 15.1 2 14V4C2 2.9 2.9 2 4 2H14C15.1 2 16 2.9 16 4M10 8H20C21.1046 8 22 8.89543 22 10V20C22 21.1046 21.1046 22 20 22H10C8.89543 22 8 21.1046 8 20V10C8 8.89543 8.89543 8 10 8Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    </div>
    <div className="friends-button" onClick={TelegramShare}>{text.buttonInviteText}</div>
    </div>
</PageWrapper>
  )
}

export default Friends