import { TaskDoneResponce } from "api/done-task";
import { motion } from "framer-motion";
import useRewardAnimation from "hooks/use-reward-animation";
import React, { useContext, useEffect, useRef, useState } from "react";
import Task from "types/task";
import AppContext from "utils/app-context";
import formatNum from "utils/format-num";

type Props = {
  rewardCount:number
  rewardData:TaskDoneResponce
  close:() => void
  onEndAnimation?: () => Promise<void>
};
const RewardScreen = ({rewardCount,rewardData,close,onEndAnimation}: Props) => {
  const {user,t} = useContext(AppContext)
  const text = t('tasksPage')
  const {
    RewardAnimationContextValue
        } = useRewardAnimation(
          {
            balanceValue:rewardData?.balance ? (user?.balance || 0) : (user?.rep || 0),
            rewardValue:Number(rewardCount),
            onEndAnimation:() => {
              close()
              if(onEndAnimation) onEndAnimation()
            }
          })


  const {
    balance,
    reward,
    endTargetRefAnimation,
    startAnimation,
    startTargetRefAnimation,
    animationItems,
    animateBalanceBox,
    setAnimateBalanceBox
  } = RewardAnimationContextValue

  if (!user) return null

  return (
    <>
      <h2 className="done-coins-title">{text.congrat}</h2>
      {rewardData?.balance ? (
        <motion.div 
           initial={{ y: 0 }}
          animate={{ scale: animateBalanceBox ? 1.1 : 1 }}
          transition={{duration:0.05}}
          onAnimationComplete={() => setAnimateBalanceBox(false)}
        className="money">
          <img 
          ref={endTargetRefAnimation as React.RefObject<HTMLImageElement>} 
          src="/img/coin4.png" alt="" width="30px" height="30px" />
          <span id="money-value">{formatNum(balance.toFixed(2),true)}</span>
        </motion.div>
      ) : (
        <motion.div 
           initial={{ y: 0 }}
          animate={{ scale: animateBalanceBox ? 1.1 : 1 }}
          transition={{duration:0.05}}
          onAnimationComplete={() => setAnimateBalanceBox(false)}
        className="money">
          <span 
          ref={endTargetRefAnimation} 
          className="big-green" style={{ fontSize: 25 }}>
            REP
          </span>
          <span id="money-value">+ {formatNum(Math.floor(balance))}</span>
        </motion.div>
      )}
      {rewardData?.balance ? (
        <div className="money-plus">
          <img
            src="/img/coin3.png"
            alt=""
            width="80px"
            height="80px"
            className="coin"
            ref={startTargetRefAnimation as React.RefObject<HTMLImageElement>} 
          />
          <span id="money-plus-value">+ {formatNum(reward.toFixed(2),true)}</span>
        </div>
      ) : (
        <div className="money-plus">
          <span 
          ref={startTargetRefAnimation} 
          className="big-green" style={{ fontSize: 25 }}>
            REP
          </span>
          <span id="money-plus-value">+ {formatNum(Math.floor(reward))} <span style={{fontFamily:'InterRegular'}}>{text.points}</span></span>
        </div>
      )}
      <div
        className="done-button"
        onClick={() =>  startAnimation()}
      >
        {text.claimReward}
      </div>
      {animationItems}
    </>
  );
};

export default RewardScreen;
