import getTags from 'api/get-tags';
import { motion } from 'framer-motion';
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import Tag from 'types/tag';
import Task from 'types/task';
import Icon from 'UI/Icon';
import AppContext from 'utils/app-context';
import formatNum from 'utils/format-num';
import tg from 'utils/tg';
import { MainContext } from 'App';

type Props = {
  min_bid:number
  rep:number
  bid:number
  task:Task
  setBid:Dispatch<SetStateAction<number>>
  tags:Tag[] | null
  settags:Dispatch<SetStateAction<Tag[] | null>>
}

const VideoTaskDetails = ({min_bid,rep,bid,setBid,task,tags,settags}:Props) => {
  const [opened, setopened] = useState(false);
  const [left, setleft] = useState(0);
  const refCategories = useRef<HTMLUListElement>(null);
  const {user} = useContext(AppContext)
  const {setNotification,updateAlert} = useContext(MainContext)

  const handleChangeReward = (n:number) => {
    if(!user) return
    const nextValue = bid + n
    if(nextValue < min_bid || user?.balance < nextValue) {
      tg.HapticFeedback.notificationOccurred('error')
      return
    }
    setBid(nextValue)
  } 

  const showHelpHint = () => {
    if(!user) return
    setNotification({text:user?.videotask_help_text})
  }

  useEffect(() => {
    if (refCategories.current) {
        const { width } = refCategories.current.getBoundingClientRect();
        setleft(width - window.innerWidth + 50);
      }
  }, [])
  
  
  return (
    <div className="video-task-info">
        <motion.div
          initial={{ y: "calc(-100% + 36px)" }}
          animate={{ y: opened ? 0 : "calc(-100% + 36px)" }}
          className="video-task-info-body"
          transition={{ ease: "circInOut" }}
          
        >
          <div className="video-task-info-buttons">
          <button className="video-task-info-hin" onClick={showHelpHint}>
            <Icon icon="hint" />
          </button>
          </div>
          
          <div className="video-task-info-categories">
            <motion.ul
              drag="x"
              dragConstraints={{ right: 0, left: -left }}
              ref={refCategories}
            >
              {tags ? tags.map(({id,title,active}) => (
                <li key={id} onClick={() => {
                  tg.HapticFeedback.selectionChanged()
                  if(tags) settags(tags?.map(t => t.id === id ? ({...t,active : !active}) : t))
                }}>
                  <p
                    style={{textDecoration:active ? 'underline' : ''}}
                  >{title}</p>
                </li>
              )) : <Icon icon='loading'/>}
            </motion.ul>
          </div>
          <div className="video-task-info-reward">
            <p className="video-task-reward-rep">
              <span className="green">REP:</span> +{rep} points
            </p>
            <div className="video-task-reward-coins">
              <button onClick={() => handleChangeReward(-1)}>
                <Icon icon="circleSubtraction" />
              </button>
              <div className="video-task-reward-coins-count">
                <img src="/img/coin4.png" alt="" />
                <span>{formatNum(bid)}</span>
              </div>
              <button onClick={() => handleChangeReward(1)}>
                <Icon icon="circleAdd" />
              </button>
            </div>
          </div>
          {/* <div className="video-task-info-buttons">
          
          </div> */}
          <button
            onClick={() => setopened(!opened)}
            className="video-task-info-button"
          >
            <motion.span
              initial={{ rotate: 180 }}
              animate={{ rotate: opened ? 0 : 180 }}
            >
              <Icon icon="arrowTop" />
            </motion.span>
          </button>
        </motion.div>
      </div>
  )
}

export default VideoTaskDetails