import React, { CSSProperties, useContext, useEffect, useState } from 'react'
import './loading-style.css'
import { useNavigate } from 'react-router-dom'
import PageWrapper from 'UI/PageWrapper'
import AppContext from 'utils/app-context'
import Icon from 'UI/Icon'
type Props = {}

const Loading = () => {
  const [loading, setLoading] = useState(0); // Прогресс загрузки (общий)
  const [realProgress, setRealProgress] = useState(0); // Реальный прогресс загрузки медиа
  const [isArtificialComplete, setIsArtificialComplete] = useState(false); // Флаг завершения искусственного прогресса
  const [canStart, setcanStart] = useState(false)
  const navigation = useNavigate();
  const { user } = useContext(AppContext);

  const size = loading * 3 + 100;
  const coinStyle: React.CSSProperties = { width: size, height: size };

  // Массив изображений для предзагрузки
  const imagesToPreload = [
    './img/Group3637.svg',
    './css/style.css',
    './img/coin3.png',
    './img/coin-2.png',
    './img/map.png',
    './img/bg-light-glow.png',
    './img/character/max.png',
    './img/character/min.png',
    './img/character/normal.png',
    './img/fog/blue.png',
    './img/fog/purple.png',
    './img/fog/white.png',
  ];

  // Функция предзагрузки изображений с обновлением загрузки
  const preloadImages = (imageUrls: string[]) => {
    let loadedImages = 0;
    const totalImages = imageUrls.length;
    const percentPerImage = Math.ceil(100 / totalImages); // Используем 100% для изображений

    return new Promise<void>((resolve) => {
      imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          loadedImages += 1;
          setRealProgress((prev) => Math.min(prev + percentPerImage, 100)); // Обновляем реальный прогресс
          if (loadedImages === totalImages) {
            resolve(); // Все изображения загружены
          }
        };
        img.onerror = () => {
          loadedImages += 1;
          setRealProgress((prev) => Math.min(prev + percentPerImage, 100));
          if (loadedImages === totalImages) {
            resolve();
          }
        };
      });
    });
  };

  useEffect(() => {
    if (!canStart) return
    let interval: NodeJS.Timeout;

    // Плавное увеличение искусственного прогресса на 1% каждые 50 мс до 80%
    interval = setInterval(() => {
      setLoading((prev) => {
        if (prev < 80) {
          return prev + 1;
        } else {
          clearInterval(interval); // Останавливаем искусственный прогресс на 80%
          setIsArtificialComplete(true); // Искусственный прогресс завершён
          return prev;
        }
      });
    }, 20); // 50 мс интервал для плавного увеличения

    // Параллельно начинается предзагрузка медиа
    preloadImages(imagesToPreload);

    return () => {
      clearInterval(interval); // Очищаем интервал при размонтировании
    };
  }, [canStart]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    // Если искусственный прогресс завершен и медиа еще не загружено
    if (isArtificialComplete && realProgress === 100) {
      interval = setInterval(() => {
        setLoading((prev) => {
          if (prev < realProgress) {
            return prev + 1; // Плавно увеличиваем реальный прогресс после завершения искусственного
          } else if (prev >= 99 && realProgress >= 99) {
            // Принудительно завершить прогресс на 100%
            clearInterval(interval);
            setLoading(100);
            setRealProgress(100);
          }
          return prev;
        });
      }, 50); // Плавное увеличение реального прогресса
    }

    return () => clearInterval(interval); // Очищаем таймеры при размонтировании
  }, [isArtificialComplete, realProgress,loading]);

  useEffect(() => {
    // Если загрузка завершена (медиа загружено на 100% и искусственный прогресс завершён), выполняем навигацию
    if (loading >= 100 && realProgress >= 100 && user) {
      let path = '';
      if (!user.lang.length) path = '/entry';
      else if (user.everyday_task) path = '/daily-reward';
      else path = '/main/home';
      navigation(path);
    }
  }, [loading, realProgress, user, navigation]);

  useEffect(() => {
    const startImages = [
      './img/Group3637.svg',
      './css/style.css',
      './img/bg-light-glow.png',
    ];
    preloadImages(startImages).then(() => {
     setcanStart(true)
    })
  }, [])

  return (
    <PageWrapper className="container">
      <div className="coin-container">
        <img src="img/Group3637.svg" alt="coin" className="coin" style={coinStyle} />
      </div>
      <div className="loading-bar">
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:10}}>
          <Icon icon='loading'/>
          <p className='flash-text'>Please wait - we're creating your coins!</p>
        </div>
        {/* <div className="percentage" id="percentage">{loading}%</div>
        <div className="progress" style={{ width: `${loading}%` }}></div> */}
      </div>
    </PageWrapper>
  );
};
export default Loading