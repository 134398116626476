import { RefObject, useContext, useEffect } from "react";
import PageWrapper from "UI/PageWrapper";
import AppContext from "utils/app-context";
import formatNum from "utils/format-num";
import FarmingButton from "./components/FarmingButton";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../../../App";
import useRewardAnimation from "hooks/use-reward-animation";
import { motion } from "framer-motion";

const Home = () => {
  const {user,updateUserInfo,t} = useContext(AppContext)
  const text = t('homeScreen')
  const navigate = useNavigate()
  const {updateAlert} = useContext(MainContext)
  const {RewardAnimationContext,RewardAnimationContextValue} = useRewardAnimation({
    balanceValue:(user?.balance || 0),
    rewardValue:(user?.farming_coins || 0)
  })
 
  const {endTargetRefAnimation,balance,animationItems,animateBalanceBox,setAnimateBalanceBox,setConfig} = RewardAnimationContextValue

  useEffect(() => {
    updateUserInfo()
    updateAlert()
  }, [])
  useEffect(() => {
    setConfig({
      balanceValue:(user?.balance || 0),
      rewardValue:(user?.farming_coins || 0)
    })
  }, [user])
  if (!user) return null
  const {for_next_level,rep,level} = user
  
  return (
    <PageWrapper>
      <div className="screen">
        <div className="main-level">
          <div className="main-level-info">
            <div className="main-level-info-rep">
              REP: <span className="thing">{level}</span>
            </div>
            <div className="main-level-info-next">
              {text.nextLevel} {formatNum(rep)} / {formatNum(for_next_level)} {text.pointsOf} REP
            </div>
          </div>
          <div className="main-info-bar">
            <div className="main-info-line" style={{width:`${100 * rep / for_next_level }%`}}></div>
          </div>
          <div className="coinsCount">
            <motion.div 
             initial={{ y: 0 }}
             animate={{ scale: animateBalanceBox ? 1.1 : 1 }}
             transition={{duration:0.05}}
             onAnimationComplete={() => setAnimateBalanceBox(false)}
            style={{gap:4, display:'flex', alignItems:'center'}}>
            <img src="\img\coin3.png" alt="" ref={endTargetRefAnimation as RefObject<HTMLImageElement> }/>
            <div className="count">{formatNum(balance,true)}</div>
            </motion.div>
            <div className="coinsCount-button" onClick={() => navigate('/main/upgrade')}>{text.upgrade}</div>
          </div>
        </div>
      </div>
      <RewardAnimationContext.Provider value={RewardAnimationContextValue}>
        <FarmingButton userData={user}/>
      </RewardAnimationContext.Provider>
      {animationItems}
    </PageWrapper>
  );
};

export default Home;
