import claimVideoTask from 'api/claim-video-task'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Task from 'types/task'
import Icon from 'UI/Icon'
import TaskTimer from 'UI/TaskTimer'
import tg from 'utils/tg'

type Props = {
    task:Task
    setfinalReward:(r:number) => void 
}

const VideoTaskStatus = ({task,setfinalReward}: Props) => {
    console.log("🚀 ~ VideoTaskStatus ~ task:", task.reward)
    const navigate = useNavigate()
    const [loading, setloading] = useState(false)
    const handleClaim = async () => {
      console.log("🚀 ~ handleClaim ~ task.is_claimed && task.seconds:", task.is_claimed,task.seconds)
      if(task.is_claimed || Number(task.seconds) > 0) return
      setloading(true)
      const result = await claimVideoTask(task.id)
      if(result?.error) {
        tg.showAlert(result.error)
      } else setfinalReward(task.vote_reward)
    }
  return (
    <div className='video-task-info pending' >
    {(!!task.vote_reward && !task.is_claimed) ? <div className="video-task-reward">
            <img src="/img/coin4.png" alt="" />
            <span>+ {task.vote_reward}</span>
        </div> : ((!task.is_claimed && Number(task.seconds) <= 0 && task.vote_reward > 0) && <span className='video-task-text'>Waiting results</span>)}
        {task.status === 2 && <TaskTimer seconds={Number(task.seconds)}/>}
        {(!!task.vote_reward && !task.is_claimed) && <span onClick={handleClaim} className='video-task-text'>{loading ? <Icon icon='loading'/> : task.is_claimed ? '' : 'claim'}</span>}
        <button className='close-video-task' onClick={() => navigate('/main/tasks')}>
            <Icon icon='close'/>
        </button>
    </div>
  )
}

export default VideoTaskStatus