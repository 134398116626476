import React, { CSSProperties, DetailedHTMLProps, ImgHTMLAttributes, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
interface Props 
{
    src:string
    size?:number
    color?:string
}

const Img = (props: Props) => {
    const {size,color} = props
    const [isLoaded, setIsLoaded] = useState(false);

    const wrapperStyles:CSSProperties = {width:size,height:size}

    if (color) {
      wrapperStyles.borderWidth = 1
      wrapperStyles.borderColor = color
      wrapperStyles.borderStyle = 'solid'
      wrapperStyles.borderRadius = 4
    }

  useEffect(() => {
    const img = new Image();
    img.src = (props.src) || '';
    img.onload = (e) => {
      setIsLoaded(true);
    };
  }, []);
  const pSize = (size || NaN) * 0.8
    return (
    <div className='image' style={wrapperStyles}>
        {
            isLoaded
            ? <motion.img 
              initial={{opacity:0}}
              animate={{opacity:1}}
            {...props} style={{maxWidth:size,maxHeight:size}}/>
            : <div className='image-placeholder-loader' style={{width:(size || 0)*0.8,height:(size || 0)*0.8}}></div>
        }
        {/* <div className='image-placeholder-loader' style={{width:(size || 0)*0.8,height:(size || 0)*0.8}}></div> */}
    </div>
  )
}

export default Img